@font-face {
  font-family: 'GT-Walsheim-Pro-Regular';
  src: url('fonts/GT-Walsheim-Pro-Regular.eot');
  src: url('fonts/GT-Walsheim-Pro-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/GT-Walsheim-Pro-Regular.woff2') format('woff2'), url('fonts/GT-Walsheim-Pro-Regular.woff') format('woff'),
    url('fonts/GT-Walsheim-Pro-Regular.svg#GT Walsheim Pro Medium') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'GT-Walsheim-Pro-Bold';
  src: url('fonts/GT-Walsheim-Pro-Bold.eot');
  src: url('fonts/GT-Walsheim-Pro-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/GT-Walsheim-Pro-Bold.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Pro-Bold.woff') format('woff'), url('fonts/GT-Walsheim-Pro-Bold.svg#GT Walsheim Pro Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'GT-Walsheim-Pro-Medium';
  src: url('fonts/GT-Walsheim-Pro-Medium.eot');
  src: url('fonts/GT-Walsheim-Pro-Medium.eot?#iefix') format('embedded-opentype'), url('fonts/GT-Walsheim-Pro-Medium.woff2') format('woff2'),
    url('fonts/GT-Walsheim-Pro-Medium.woff') format('woff'), url('fonts/GT-Walsheim-Pro-Medium.svg#GT Walsheim Pro Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}
